import React from "react";
import { graphql } from "gatsby";

import { Layout, MaterialCard, SectionTitle, SEO } from "components";
import { useTranslation } from "react-i18next";

interface queryData {
  data: {
    allMdx: {
      nodes: [
        {
          frontmatter: {
            title: string;
            date: any;
            description: string;
          };
          id: string;
          slug: string;
        }
      ];
    };
  };
}

const materials = ({ data }: queryData) => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO
        title={t`config.nav.materials`}
        description={t`informations.paragraph_1`}
      />
      <article className="materials container">
        <SectionTitle title={t`config.title.materials`} />
        <section className="materials__container">
          {data.allMdx.nodes.map((node) => (
            <div className="materials__card" key={node.id}>
              <MaterialCard
                title={node.frontmatter.title}
                date={node.frontmatter.date}
                text={node.frontmatter.description}
                link={`/materials/${node.slug}`}
              />
            </div>
          ))}
        </section>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          date(formatString: "D.MM.Y")
          title
          description
          path
        }
        id
        slug
      }
    }
  }
`;

export default materials;
